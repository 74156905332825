








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RightNavigation, RightNavLink } from '@/components/rightnav';

@Component({
  components: {
    RightNavigation,
    RightNavLink,
  },
})
export default class SettingsPage extends Vue {}
